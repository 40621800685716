<template>
	<v-sheet class="inventory" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex class="d-flex align-items-center">
							<p class="my-auto font-level-3-bold" style="font-size: 20px">
								<ShowValue :object="service" object-key="name" label="Service name"></ShowValue>
							</p>
							<ShowBarcode
								:object="service"
								object-key="barcode"
								tooltip
								tooltip-text="service #"
								click-to-copy
								label="barcode"
							></ShowBarcode>
						</v-flex>
						<v-flex md7 class="text-right">
							<template v-if="false && inventory.retired">
								<v-btn
									v-on:click="doAction('stock-history')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-history</v-icon>Stock History</v-btn
								>
								<v-btn
									v-on:click="doAction('print-label')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-printer</v-icon>Print Label</v-btn
								>
							</template>
							<template v-else>
								<v-btn
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									v-if="getPermission('service::edit')"
									v-on:click="doAction('edit')"
								>
									<v-icon small left>mdi-pencil</v-icon>Edit
								</v-btn>
								<v-btn
									v-on:click="backToListing('service')"
									color="grey lighten-3"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
								>
								<v-menu v-if="false" offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
											><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
										>
									</template>
									<v-list>
										<v-list-item v-if="false" v-on:click="doAction('stock-history')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-history</v-icon>
												Stock History</v-list-item-title
											>
										</v-list-item>
										<v-list-item v-if="false" v-on:click="doAction('print-label')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-printer</v-icon>
												Print Label</v-list-item-title
											>
										</v-list-item>
										<v-list-item
											v-if="inventory.retired == 0 && getPermission('inventory:update')"
											v-on:click="doAction('edit')"
										>
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('inventory:create')" v-on:click="doAction('duplicate')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-content-duplicate</v-icon>
												Clone
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('inventory:delete')" v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
										<!-- Used In responsive -->
										<template v-if="deviceWidth < 1360.98">
											<template v-if="!inventory.retired && inventory.initial_stock > 0">
												<v-list-item v-if="getPermission('checkout:create')" v-on:click="doAction('checkout')">
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-share-outline</v-icon>
														Issue
													</v-list-item-title>
												</v-list-item>
												<v-list-item
													v-if="getPermission('inventory:update')"
													v-on:click="doAction('retire-stock')"
												>
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
														Retire Stock
													</v-list-item-title>
												</v-list-item>
												<v-list-item v-if="getPermission('inventory:update')" v-on:click="doAction('retire')">
													<v-list-item-title>
														<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
														Retire
													</v-list-item-title>
												</v-list-item>
											</template>
										</template>
									</v-list>
								</v-menu>
								<template v-if="deviceWidth > 1360.02">
									<template v-if="!inventory.retired && inventory.initial_stock > 0">
										<v-btn
											v-if="getPermission('checkout:create')"
											v-on:click="doAction('checkout')"
											color="blue darken-4 text-white"
											class="mx-2"
											depressed
											tile
										>
											<v-icon small left>mdi-share-outline</v-icon>Issue
										</v-btn>
										<v-btn
											v-if="getPermission('inventory:update')"
											v-on:click="doAction('retire-stock')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-cancel</v-icon>Retire Stock</v-btn
										>
										<v-btn
											v-if="getPermission('inventory:update')"
											v-on:click="doAction('retire')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-cancel</v-icon>Retire</v-btn
										>
									</template>
									<v-btn
										v-if="false"
										v-on:click="doAction('reserve')"
										color="blue darken-4 text-white"
										class="ml-2"
										depressed
										tile
										><v-icon small left>mdi-calendar-check</v-icon>Reserve</v-btn
									>
								</template>
								<v-btn
									v-if="inventory.retired == 0 && getPermission('inventory:update')"
									v-on:click="doAction('add-stock')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-playlist-plus</v-icon>Add Stock</v-btn
								>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="service.image"></ImageTemplate>
						</div>
						<div class="w-100">
							<div class="d-flex flex-wrap mt-4">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Reference : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="service" object-key="reference" label="Reference"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600"> Category : </span>
										<ShowValue :object="service" object-key="category" label="category "></ShowValue>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Charges : </span>
										<span class="fw-500 no-wrap">
											<ShowPrice :object="service" object-key="charges" label="Charges"></ShowPrice>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Created date : </span>
										<span class="fw-500" v-if="service.added_at">
											{{ formatDateTime(service.added_at) }}
										</span>
										<em v-else class="text-muted">no created date</em>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Created By : </span>
										<span class="fw-500">
											<ShowValue :object="service" object-key="added_by" label="created by"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated date : </span>
										<span class="fw-500" v-if="service.updated_at">
											{{ formatDateTime(service.updated_at) }}
										</span>
										<em v-else class="text-muted">no updated date</em>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated By : </span>
										<span class="fw-500">
											<ShowValue :object="service" object-key="updated_by" label="updated by"></ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="w-20 pr-3" v-if="false && inventory.pico_barcode && inventory.barcode_image">
							<div class="mt-2">
								<span class="fw-600">Pico Barcode : </span>
								<span class="fw-500">
									<Barcode circle :src="inventory.barcode_image"></Barcode>
								</span>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="inventoryTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#quantity-by-location" v-if="false">
						<v-icon small left>mdi-map-marker-outline</v-icon> Quantity by Location</v-tab
					>
					<v-tab v-if="getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab v-if="false" href="#reservation">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
					<v-tab v-if="false" href="#retire_stock_records">
						<v-icon small left>mdi-calendar-check</v-icon> Retire Stock Records</v-tab
					>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
					<v-tab href="#related-service" v-if="false">
						<v-icon small left>mdi-hammer-wrench</v-icon> Related Services</v-tab
					>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders</v-tab
					>
					<v-tab v-if="false" href="#file">
						<v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab
					>
					<v-tab v-if="false" href="#comment">
						<v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab
					>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support</v-tab
					>
				</v-tabs>

				<v-tabs-items v-model="inventoryTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/inventory-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Service Details
									</v-flex>
								</v-layout>
								<table width="100%" class="mt-3 detail-table scattered-table">
									<tr>
										<th width="100px" valign="top">Description :</th>
										<td colspan="3" valign="top">
											<ShowTextAreaValue
												:object="service"
												object-key="description"
												label="description"
											></ShowTextAreaValue>
											<TooltipQuestion v-if="false">
												<template v-slot:text
													>For all locations without specified<br />thresholds, you will receive an<br />email
													alert when stock<br />quantity is higher than this value</template
												>
											</TooltipQuestion>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="retire_stock_records">
						<ItemRetirementsListing
							v-if="inventoryTab == 'retire_stock_records'"
							class="mx-4"
							type-text="Inventory"
							type="inventory"
							:uuid="uuid"
						></ItemRetirementsListing>
					</v-tab-item>
					<v-tab-item value="quantity-by-location">
						<QuantityLocation
							v-if="inventoryTab == 'quantity-by-location'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></QuantityLocation>
					</v-tab-item>
					<v-tab-item value="transaction">
						<template v-if="inventoryTab == 'transaction'">
							<TransactionListing
								relation-type="inventory"
								:relation-type-id="inventory.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="reservation">
						<ReservationListing
							v-if="inventoryTab == 'reservation'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="inventoryTab == 'event'"
							class="mx-4"
							type-text="Service"
							type="service"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="related-service" v-if="false">
						<RelatedServiceTemplate
							v-if="inventoryTab == 'related-service'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></RelatedServiceTemplate>
					</v-tab-item>
					<v-tab-item value="work-order" v-if="false">
						<WorkOrderListing
							v-if="inventoryTab == 'work-order'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="inventoryTab == 'file'"
							class="mx-4"
							type-text="Perishables Files"
							type="inventory"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							v-if="inventoryTab == 'comment'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support">
						<SupportTemplate
							v-if="inventoryTab == 'support'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Perishables"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!inventory.retired
						? 'To preserve associated records, you could retire the Perishables instead.'
						: null
				"
			>
				<template v-if="!inventory.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<template v-if="retireDialog">
				<RetireTemplate
					type="Inventory"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="goBack()"
					v-on:close="retireDialog = false"
					:retire-dialog="retireDialog"
				></RetireTemplate>
			</template>
			<template v-if="retireStockDialog">
				<RetireStockTemplate
					type="Inventory"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="goBack()"
					v-on:close="retireStockDialog = false"
					:retire-dialog="retireStockDialog"
				></RetireStockTemplate>
			</template>
			<template v-if="reservationDialog">
				<ReservationTemplate
					type="Inventory"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="
						reservationDialog = false;
						getInventory();
					"
					v-on:close="reservationDialog = false"
					:reservation-dialog="reservationDialog"
				></ReservationTemplate>
			</template>
			<AddStockTemplate v-on:success="getInventory"></AddStockTemplate>
			<template v-if="checkoutDialog">
				<CheckoutTemplate
					type="Perishable"
					endpoint="inventory"
					:type-uuid="uuid"
					v-on:success="
						checkoutDialog = false;
						getInventory();
					"
					v-on:close="checkoutDialog = false"
					:checkout-dialog="checkoutDialog"
				></CheckoutTemplate>
			</template>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import QuantityLocation from "@/view/components/QuantityLocation";
import ReservationListing from "@/view/components/ReservationListing";
import TransactionListing from "@/view/components/TransactionListing";
import EventListing from "@/view/components/EventListing";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import { GetService } from "@/core/lib/service.lib";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import AddStockTemplate from "@/view/components/AddStockTemplate";
// import { EventBus } from "@/core/event-bus/event.bus";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import RetireTemplate from "@/view/components/RetireTemplate";
import RetireStockTemplate from "@/view/components/RetireStockTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import RelatedServiceTemplate from "@/view/components/RelatedServiceTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import ItemRetirementsListing from "@/view/components/ItemRetirementsListing";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import ShowBarcode from "../../components/ShowBarcode.vue";

export default {
	name: "Service-detail",
	title: "Detail Services",
	data() {
		return {
			inventoryTab: null,
			uuid: null,
			barcode: null,
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			checkoutDialog: false,
			retireDialog: false,
			contentLoaded: true,
			retireStockDialog: false,
			reservationDialog: false,
			inventory: {},
			deviceWidth: document.documentElement.clientWidth,
			service: {
				name: "Lorem ipsum",
				reference: "Lorem ipsum",
				barcode: "SER0000004443",
				charges: "4443",
				category: "Software",
				description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, iusto.",
			},
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	methods: {
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "service-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "service-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
			}
		},
		getInventory() {
			//
		},
		getService() {
			GetService(this.uuid)
				.then((data) => {
					(this.service = {
						name: data.name,
						image: data.image,
						reference: data.reference,
						barcode: data.barcode,
						charges: data.charges,
						category: data.group_relation.name,
						description: data.description,
						added_at: data.added_at,
						added_by: data.created_by ? data.created_by : data.added_by,
						updated_at: data.updated_at,
						updated_by: data.updated_by,
					}),
						(this.barcode = data.barcode);
					this.inventory = data;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `service/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Service", disabled: true, href: "/service" },
						// { text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true, href: "#" },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		Comments,
		TooltipQuestion,
		QuantityLocation,
		ReservationListing,
		TransactionListing,
		EventListing,
		WorkOrderListing,
		ImageTemplate,
		CheckoutTemplate,
		ShowValue,
		ShowPrice,
		ItemRetirementsListing,
		AddStockTemplate,
		DeleteTemplate,
		RetireTemplate,
		RetireStockTemplate,
		ReservationTemplate,
		RelatedServiceTemplate,
		SupportTemplate,
		Barcode,
		ShowTextAreaValue,
		ShowBarcode,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Service", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		// console.log({uuid_detail:this.uuid })
		this.getService();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
